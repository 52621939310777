import * as React from "react"
import Img from "gatsby-image"
import '../scss/components/callout.scss'

class Callout extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div className="callout">
        <div className="row flow--thick">
          <div className="col-12 col-md-6 flow">
            <h3 className="h2">{data.calloutTitle}</h3>
            <p>{data.calloutDescription.calloutDescription}</p>
            <div className="text-center text-md-start">
              <a href={data.calloutLink.linkUrl} className="btn">Estimate Equipment Costs</a>
            </div>
          </div>
          <div className="col-12 col-md-6">
          <Img
            alt={data.calloutImage.description}
            className="callout__img"
            fluid={data.calloutImage.fluid}
          />
          </div>
        </div>
      </div>
    )
  }
}

export default Callout


