import React, { Component } from "react"
import get from "lodash/get"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from 'gatsby-image'
import "../scss/components/heroCarousel.scss"

class HeroCarousel extends Component {
  render() {
    const slides = get(this, "props.data.slideOrder")

    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        interval="6500"
        infiniteLoop={true}
        className="hero--carousel"
      >
        {slides.map((item, index) => (
          <BackgroundImage
            className={`hero hero__container hero__img ${item.slideVideo ? 'hero__video-img' : ''}`}
            Tag="div"
            fluid={item.slideImage.fluid}
            key={item.id}
          >
            {item.slideVideo && (
              <video
                title={item.slideVideo.description}
                className="hero--carousel__video"
                loop
                controls
                muted
                poster={item.slideImage.fluid.src}
                preload="none"
                autoPlay
                playsInline
              >
                <source src={item.slideVideo.file.url} type="video/mp4" />
              </video>
            )}
            <div className="hero__content container">
              <div className="hero__text col-sm-7 col-md-6 col-lg-7 col-xl-6 flow">
                <h1 className="hero__title">{item.title}</h1>
                <h2 className="hero__subtitle">{item.subtitle}</h2>
                <a href={item.linkUrl} className="btn">
                  {item.linkText}
                </a>
              </div>
            </div>

            {item.slideImageForeground && 
              <Img
                alt={item.slideImageForeground.description} 
                fluid={item.slideImageForeground.fluid}
                className="hero__img-fg" 
              />
            }
          </BackgroundImage>
        ))}
      </Carousel>
    )
  }
}

export default HeroCarousel
