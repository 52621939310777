import * as React from "react"
import Img from "gatsby-image"
import "../scss/components/carousel.scss"
import "../scss/components/news-feed.scss"

const NewsFeed = ({ title, description, img, link, externalIcon }) => (
  <div className="news-feed carousel__post">
    <a href={link} target="_blank" rel="noopener noreferrer">
      {img && <Img alt={img.description} fluid={img.fluid} />}
    </a>
    <div className="carousel__content">
      {title && <h3 className="news-feed--title h4">{title}</h3>}
      {description && <p className="news-feed__desc">{description}</p>}
      <a
        className="news-feed--link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        See More
        <Img alt="" className="open-icon" fluid={externalIcon} />
      </a>
    </div>
  </div>
)

export default NewsFeed
