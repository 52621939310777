import * as React from "react"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import "../scss/components/quote.scss"

const Quote = ({ data }) => {
  return (
    <div className="quote">
      <div className="quote__text container flow">
        <StaticImage
          src="../images/quote-marks.png"
          alt="blue quotation marks"
          className="quote__marks"
          placeholder="blurred"
          layout="fullWidth"
        />
        <p className="quote__phrase">{data.quoteText.quoteText}</p>
        <p className="quote__author">{data.quoteAuthor}</p>
        <a href="/resources/#what-experts-say" className="btn">What Experts Say</a>
      </div>
      <div className="quote__img">
        <Img
          alt={data.quoteAuthorImage.description}
          fluid={data.quoteAuthorImage.fluid}
        />
      </div>
    </div>
  )
}

export default Quote