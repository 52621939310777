import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import { StaticImage } from "gatsby-plugin-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Callout from "../components/callout"
import Quote from "../components/quote"
import Seo from "../components/seo"
import HeroCarousel from "../components/heroCarousel"
import NewsFeed from "../components/newsFeed"
import "../scss/pages/index.scss"

import Nematicide from "../images/icon-nematicide.inline.svg"
import Insecticide from "../images/icon-insecticide.inline.svg"
import Fungicide from "../images/icon-fungicide.inline.svg"
import Micronutrients from "../images/icon-micronutrients.inline.svg"

class IndexPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main p, main .btn, .news-feed--title"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replaceAll('®', "<sup>&reg;</sup>");
    })

    // Adding some &nbsp;
    let calloutText = document.querySelectorAll(".callout p, .callout h3, .hero__subtitle"); 
    calloutText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('your operation', "your&nbsp;operation");
      el.innerHTML = el.innerHTML.replace('SIMPAS System', "SIMPAS&nbsp;System");
      el.innerHTML = el.innerHTML.replace("brands & models", "brands &&nbsp;models");
      el.innerHTML = el.innerHTML.replace("brands &amp; models", "brands&nbsp;&amp;&nbsp;models");
      el.innerHTML = el.innerHTML.replace("in-furrow simultaneously", "in&#8209;furrow simultaneously");
    })
  }
  render() {
    // Component Content
    const carouselContent = get(this, "props.data.contentfulCarousel")
    const quoteContent = get(this, "props.data.contentfulQuote")
    const calloutContent = get(this, "props.data.contentfulCallout")
    const blogPosts = get(this, "props.data.allContentfulNews.edges")
    const externalIcon = get(this, "props.data.externalIcon")

    // Images
    const simpasEquipmentImg = get(this, "props.data.simpasEquipmentImg")

    return (
      <Layout bodyClass="home">
        <Seo title="Home" />
        <section className="padding--none">
          <HeroCarousel data={carouselContent} />
        </section>

        <section className="bg--gray" id="gameChangingTechnology">
          <div className="container">
            <div>
              <div className="row d-flex flex-column-reverse flex-md-row flow">
                <div className="col-12 col-md-6">
                  <Img
                    alt={simpasEquipmentImg.description}
                    className="img--absolute"
                    fluid={simpasEquipmentImg.fluid}
                  />
                </div>
                <div className="col-12 col-md-6 flow">
                  <h2>Reimagine In-furrow Application</h2>
                  <p>
                    SIMPAS-applied Solutions&trade; (SaS&trade;) and the SIMPAS&reg; application system 
                    are the next evolution in prescriptive
                    application, using agronomic analyses from a variety of data
                    sources to develop prescriptions that are precisely and
                    variably applied in-furrow at planting. The SIMPAS system
                    also utilizes patented hardware and an intuitive ISO-based
                    user interface to create a closed delivery system that
                    maximizes ROI and improves sustainability.
                  </p>
                  <ul className="icon-group--inline">
                    <li className="icon-group--inline__item">
                      <Nematicide className="icon-group__icon" />
                      <span>Nematicide</span>
                    </li>
                    <li className="icon-group--inline__item">
                      <Insecticide className="icon-group__icon" />
                      <span>Insecticide</span>
                    </li>
                    <li className="icon-group--inline__item">
                      <Fungicide className="icon-group__icon" />
                      <span>Fungicide</span>
                    </li>
                    <li className="icon-group--inline__item">
                      <Micronutrients className="icon-group__icon" />
                      <span>Micronutrients</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="padding--none">
          <Quote data={quoteContent} />
        </section>

        {/* Get Started with SIMPAS */}
        <section className="bg--blue">
          <div className="container flow--thick">
            <div className="get-started bg--white">
              <div className="text-center">
                <h2>Get Started with SIMPAS</h2>
                <p>
                  Change the way you treat pressure areas in three simple steps.
                </p>

                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <StaticImage
                        src="../images/icon-1.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number">1</p>
                        <p className="gs__text">
                          Contact your crop inputs retailer to review the SaS product&nbsp;portfolio.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <StaticImage
                        src="../images/icon-2.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number">2</p>
                        <p className="gs__text">
                          Work with your trusted advisor to develop
                          prescriptions that address targeted agronomic&nbsp;needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <StaticImage
                        src="../images/icon-3.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number">3</p>
                        <p className="gs__text">
                          Purchase SIMPAS equipment from a participating Trimble&reg;&nbsp;Dealer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <a href="/find-a-provider/" className="btn btn--white">
                Find a Dealer
              </a>
            </div>
          </div>
        </section>
        {/* END - Get Started with SIMPAS */}

        <section>
          <div className="text-center">
            <div className="container">
              <h3 className="h2">Latest Updates</h3>
              <p>
                See what’s happening with SIMPAS-applied Solutions and SIMPAS.
              </p>
            </div>
            <div className="container-lg">
              <div className="text-left">
                <div className="carousel__container flow--thick">
                  <div className="row carousel">
                    {blogPosts
                      .filter((item, idx) => idx < 3)
                      .map(({ node }) => {
                        return (
                          <div
                            className="col-md-4 carousel__item"
                            key={node.id}
                          >
                            <NewsFeed
                              img={node.featuredImage}
                              title={node.headline}
                              description={node.description}
                              link={node.link}
                              externalIcon={externalIcon.fluid}
                            />
                          </div>
                        )
                      })}
                  </div>
                  <div className="text-center">
                    <a className="btn" href="/news/">See All News</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <Callout data={calloutContent} />
          </div>
        </section>
      </Layout>
    )
  }
}
export default IndexPage

export const IndexPageQuery = graphql`
  query indexPageQuery {
    contentfulCarousel(carouselName: { eq: "Home - Revised" }) {
      slideOrder {
        id
        linkText
        linkUrl
        slideImage {
          fluid(maxWidth: 1440, resizingBehavior: PAD, background: "#000", quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        slideImageForeground {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        slideVideo {
          file {
            url
          }
          description
        }
        subtitle
        title
      }
    }
    contentfulQuote(quoteName: { eq: "Home - Jason Orr" }) {
      id
      quoteAuthor
      quoteName
      quoteText {
        quoteText
      }
      quoteAuthorImage {
        fluid(maxWidth: 750, resizingBehavior: PAD, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      quoteUrl
    }
    allContentfulNews(sort: { fields: publicationDate, order: DESC }) {
      edges {
        node {
          id
          headline
          description
          publicationDate(formatString: "LL")
          featuredImage {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            description
          }
          link
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(resizingBehavior: SCALE, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            description
          }
          description {
            description
          }
        }
      }
    }
    contentfulCallout(contentful_id: { eq: "gN4win6RayHwnfsNemmH4" }) {
      id
      calloutTitle
      calloutDescription {
        calloutDescription
      }
      calloutImage {
        fluid(maxWidth: 750, resizingBehavior: PAD, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      calloutLink {
        linkUrl
      }
    }
    simpasEquipmentImg: contentfulAsset(title: { eq: "simpas-equipment" }) {
      id
      description
      title
      fluid(maxWidth: 768, resizingBehavior: PAD, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    externalIcon: contentfulAsset(title: { eq: "open-icon" }) {
      id
      description
      title
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
